import card1 from "../Assets/Images/Testimonials/card1.png";
import card2 from "../Assets/Images/Testimonials/card2.png";
import card3 from "../Assets/Images/Testimonials/card3.png";
import star from "../Assets/Images/Testimonials/star.png";

const TestimonialData = [
  {
    id: 1,
    img: card1,
    name: "Sonal Patel",
    role: "Student",
    star: star,
    desc: "K3 study has been a great help to us. The faculty is knowledgeable and supportive. The infrastructure and teaching methods are of the highest quality.    .",
  },
  {
    id: 2,
    img: card2,
    name: "Ankit Anand",
    role: "Student",
    star: star,
    desc: "K3 study has been a great help to us. The faculty is knowledgeable and supportive. The infrastructure and teaching methods are of the highest quality."

  },
  {
    id: 3,
    img: card3,
    name: "Rahul Agarwal",
    role: "Student",
    star: star,
    desc: "K3 study has been a great help to us. The faculty is knowledgeable and supportive. The infrastructure and teaching methods are of the highest quality."    
  },
  {
    id: 4,
    img: card1,
    name: "Sonal Patel",
    role: "Student",
    star: star,
    desc: "K3 study has been a great help to us. The faculty is knowledgeable and supportive. The infrastructure and teaching methods are of the highest quality."
    ,
  },
  {
    id: 5,
    img: card2,
    name: "Ankit Anand",
    role: "Student",
    star: star,
    desc: "K3 study has been a great help to us. The faculty is knowledgeable and supportive. The infrastructure and teaching methods are of the highest quality."
  },
  {
    id: 6,
    img: card3,
    name: "Rahul Agarwal",
    role: "Student",
    star: star,
    desc: "K3 study has been a great help to us. The faculty is knowledgeable and supportive. The infrastructure and teaching methods are of the highest quality."

  },
];
export default TestimonialData;
