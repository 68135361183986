import React from "react";
import AboutGmat from "../Components/Gmat Components/About GMAT/AboutGmat";
import StudentReview from "../Components/Student Reviews/StudentReview";
import Contact from "../Components/Landing Page Components/Contact/Contact";
import Hero from "../Common/Hero/Hero";
import main from "../Assets/Images/Pte/main.png";
import { useEffect } from "react";

const GmatPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className="k3study_hero-counter">
        <Hero
          img={main}
          title="Online GMAT Course"
          paragraph="Our GMAT coaching institute offers comprehensive training for the Graduate Management Admission Test. Our experienced instructors provide lessons covering all areas of the exam, including quantitative, verbal, and analytical writing. We also provide personalized practice exercises to hone your skills and maximize your score. With our proven techniques and strategies, we have helped thousands of students achieve their education and career goals."
        />
      </div>
      <AboutGmat />
      <StudentReview />
      <Contact />
    </div>
  );
};

export default GmatPage;
