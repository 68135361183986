import React from "react";
import AboutIelets from "../Components/Landing Page Components/AboutIelets/AboutIelets";
import Benefits from "../Components/Landing Page Components/Benefits/Benefits";
import Contact from "../Components/Landing Page Components/Contact/Contact";
import Counter from "../Components/Landing Page Components/Counter/Counter";
import HeroCarousal from "../Components/Landing Page Components/HeroCarousal/HeroCarousal";
import Introduction from "../Components/Landing Page Components/Introduction/Introduction";
import Practice from "../Components/Landing Page Components/Practice/Practice";
import Testimonials from "../Components/Landing Page Components/Testimonials/Testimonials";
import { useEffect } from "react";

const LandingPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className="k3study_hero-counter">
        <HeroCarousal />
        <Counter />
      </div>
      <AboutIelets />
      <Introduction />
      <Benefits />
      <Practice />
      <Testimonials />
      <Contact />
    </div>
  );
};

export default LandingPage;
