import React from "react";
import "./aboutbusiness.css";
import img1 from "../../../Assets/Images/About/img1.png";
import img2 from "../../../Assets/Images/About/img2.png";
import img3 from "../../../Assets/Images/About/img3.png";
import img4 from "../../../Assets/Images/About/img4.png";
import img5 from "../../../Assets/Images/About/img5.png";
import pic1 from "../../../Assets/Images/About/pic1.png";
import pic2 from "../../../Assets/Images/About/pic2.png";
import pic3 from "../../../Assets/Images/About/pic3.png";

import { FaUserCircle } from "react-icons/fa";

const AboutBusiness = () => {
  return (
    <div className="k3study_about-business">
      <h2>We are here for Business We are the fastest growing brand</h2>
      <p>
        Our customized study plans are tailored to each student's individual
        needs and learning styles. With our experienced instructors, we can help
        you develop the skills you need to excel in IELTS, PTE, TOEFL, OET,
        GMAT, and SAT exams. Let us help you reach your educational goals.
      </p>
      <p>
        Our experienced trainers provide adapted IELTS, PTE, TOEFL, OET, GMAT,
        and SAT coaching to help you achieve your desired score in the exam. Our
        tailored programs are specifically designed to suit your needs and help
        you reach your goals.
      </p>
      <img src={img1} alt="" />
      <h4>Our Vision</h4>
      <p>
        At K3 study, we are committed to providing our students with a
        comprehensive and effective learning experience that will help them
        achieve their academic and professional goals. We strive to be an
        international leader in IELTS, PTE, TOEFL, OET, GMAT and SAT coaching by
        providing the highest quality of instruction and support to our
        students. We believe that our courses and services should be accessible,
        affordable, and effective in helping students reach their educational
        goals. Through our years of experience and expertise, we strive to
        create an environment that fosters learning, growth, and success. Our
        mission is to provide our students with the skills and knowledge needed
        to excel in their chosen fields and to help them to reach their full
        potential.
      </p>
      <img src={img2} alt="" />
      <h4>Our Mission</h4>
      <p>
        We are here to guide you through the right way in achieving the
        successful completion of your milestones in your career.
      </p>
      <img src={img3} alt="" />
      <h4>Our Story</h4>
      <p>
        K3 Study is an online educational platform that provides comprehensive
        and affordable training and guidance for students preparing to take
        IELTS, PTE, TOEFL, OET, GMAT, and SAT exams. Our experienced team of
        teachers and mentors has years of experience in exam preparation and has
        helped hundreds of students pass their tests with flying colors. We
        understand the importance of passing these exams for our students and
        strive to provide them with the best possible preparation. We offer
        detailed study plans and practice materials that are tailored to the
        individual student’s needs and our comprehensive training will help them
        build the skills and confidence they need to excel in their exams. At K3
        Study, we believe that success comes from hard work and dedication and
        our goal is to help our students reach their highest potential. Our team
        of experts is always available to answer questions and provide guidance
        and support whenever needed. Our mission is to provide the best possible
        preparation for our students and help them achieve their goals. We are
        committed to helping our students reach their dreams and we are proud to
        have played a part in the success stories of our students. We invite you
        to join us on our journey and take the first step toward success.
      </p>
      <div className="k3study_about-business-choose-us">
        <h2>Why Choose Us?</h2>
        <p>
          At K3 Study, we understand the importance of preparing for competitive
          exams like IELTS, PTE, TOEFL, OET, GMAT, and SAT. We provide
          comprehensive coaching and practice material to help you ace these
          exams. Our experienced team of dedicated professionals is committed to
          helping you achieve your desired score. Our experienced and certified
          instructors have extensive knowledge of the IELTS, PTE, TOEFL, OET,
          GMAT, and SAT exams, and they use their expertise to provide you with
          the most comprehensive and up-to-date coaching. We offer comprehensive
          course packages that are tailored to meet the individual needs of each
          student. Our packages include several components such as practice
          tests, mock exams, and personalized feedback to ensure that each
          student is prepared for the exam and is equipped with the necessary
          skills to succeed. We also provide ample study materials and resources
          to help you understand the exam format and content. Our experienced
          instructors and comprehensive course materials provide the perfect
          combination to help you succeed in the exams. We offer flexible
          learning options to suit the needs of our students.
        </p>
        <p>Experienced instructors</p>
        <p>Flexible Course Packages.</p>
        <p>24*7 support</p>
        <div className="k3study_about-business-choose-us_main">
          <div className="k3study_about-business-choose-us-section1">
            <img src={img4} alt="" />
            <h6>Support from expert team</h6>
            <p>
              Our Regional Managers and expert team at the head office are just
              a call/text away and eager to offer solutions that your business
              needs.
            </p>
          </div>
          <div className="k3study_about-business-choose-us-section1">
            <img src={img5} alt="" />
            <h6>Career Opportunities</h6>
            <p>
              Join the fastest growing EdTech brand offering endless learning
              and growth opportunities and advance your career with us.
            </p>
          </div>
        </div>
      </div>
     {/* <div className="k3study_about-business-leadership">
        <h2>Our Leadership</h2>
        <div className="k3study_about-business-leadership-main">
          <div className="k3study_about-business-leadership-section1">
             <img src={pic1} alt="" /> 
            <FaUserCircle className="k3study_about-business_leader-img" />
            <h4>David Fincher</h4>
            <p>Co-Founder & CEO</p>
          </div>
          <div className="k3study_about-business-leadership-section2">
             <img src={pic2} alt="" /> 
            <FaUserCircle className="k3study_about-business_leader-img" />
            <h4>Olivia Williams</h4>
            <p>Co-Founder</p>
          </div>
          <div className="k3study_about-business-leadership-section3">
             <img src={pic3} alt="" /> 
            <FaUserCircle className="k3study_about-business_leader-img" />
            <h4>Scott Robin</h4>
            <p>Executive Director</p>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default AboutBusiness;
