import React from "react";
import "./aboutielets.css";
import RedCards, {
  PurpleCards,
  YellowCards,
} from "../../../Common/Cards/Cards";

const AboutIelets = () => {
  return (
    <div className="k3study_about-ielets">
      <div className="k3study_about-ielets_title-div">
        <h1 className="k3study_about-ielets_title">
          Unlock Your Potential
          <span className="k3study_about-ielets-blue"> with K3Study</span>
        </h1>
        <p className="k3study-about-ielets-paragraph">
          At K3 Study, we are dedicated to helping you reach your academic and
          professional goals. Our team of experienced and knowledgeable
          instructors is equipped to provide you with the highest quality of
          tutoring services in the areas of IELTS, PTE, TOEFL, OET, GMAT, and
          SAT.
        </p>
      </div>
      <div className="k3study-card-container">
        <RedCards
          heading="Study"
          paragraph="IELTS scores shows your English language proficiency, which is required for study abroad. It helps universities to measure a student's ability to communicate in an academic environment
          ."
        />
        <YellowCards
          heading="Migration"
          paragraph="IELTS score can be used to meet migration requirements. Higher the score, better the chance of obtaining visa and migrating to the desired country
          ."
        />
        <PurpleCards
          heading="Work"
          paragraph="IELTS scores can demonstrate your language proficiency, giving employers confidence in your ability to communicate on the job - an essential requirement in many roles."
        />
      </div>
    </div>
  );
};

export default AboutIelets;
