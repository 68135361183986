import React from "react";
import StudentReview from "../Components/Student Reviews/StudentReview";
import  {
  DemoContact,
} from "../Components/Landing Page Components/Contact/Contact";
import AboutSat from "../Components/Sat Components/About SAT/AboutSat";
import Hero from "../Common/Hero/Hero";
import satmain from "../Assets/Images/SAT/satmain.png";
import { useEffect } from "react";

const SatPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className="k3study_hero-counter">
        <Hero img={satmain} title="Online SAT course" paragraph="Our SAT coaching institute provides comprehensive training for the Scholastic Aptitude Test. Our experienced instructors provide lessons covering all areas of the exam, including reading, writing, and math. We also provide personalized practice exercises to hone your skills and maximize your score. With our proven techniques and strategies, we guarantee success in the exam."/>
      </div>
      <AboutSat />
      <StudentReview />
      <DemoContact />
    </div>
  );
};

export default SatPage;
