import React from "react";
import "./studentreview.css";
import rating1 from "../../Assets/Images/Ielets/rating1.png";
import rating2 from "../../Assets/Images/Ielets/rating2.png";
import rating3 from "../../Assets/Images/Ielets/rating3.png";
import arrow1 from "../../Assets/Images/Ielets/arrow1.png";
import arrow2 from "../../Assets/Images/Ielets/arrow2.png";
import quotes from "../../Assets/Images/Ielets/quotes.png";
// import background from "../../Assets/Images/Ielets/background.png";

import IeletsTestimonialCard from "../Ielets Components/Testimonial Card/IeletsTestimonialCard";

const IeletsRating = () => {
  return (
    <div className="k3study_rating-main">
      <div className="k3study_ielets-rating">
        <div className="k3study_ielets-rating-section1">
          <img src={quotes} className="k3study_ielets-rating-quotes" alt="" />
          <h2 className="k3study_text">What our students say about us</h2>
          <h4 className="k3study_text-paragraph">
            Our students have said wonderful things about us. They have found
            that our courses are engaging and informative, our staff is friendly
            and helpful, and the atmosphere of our school is conducive to
            learning. Many of our students have said that the school has become
            a second home to them, allowing them to make great friends, build
            connections, and become the best version of themselves. Our students
            are proud to be part of the school and have often said it helped
            them develop their talents, grow as individuals, and reach their
            academic and professional goals.
          </h4>
        </div>
        <div className="k3study_ielets-rating-section2">
          <div className="k3study_rating-img_div">
            {/* <FaUserCircle/> */}
            <IeletsTestimonialCard
              name="Mahesh Karna"
              adress="Pune, Maharashtra"
              desc="I would like to thank K3Study. I can say K3Study is one of the best institutes for IELTS coaching."
            />
            <IeletsTestimonialCard
              name="Akul Reddy"
              adress="Pune, Maharashtra"
              desc="Trainers are very good at teaching and taking care of each and every student."
            />
            <IeletsTestimonialCard
              name="Maha Lakshmi"
              adress="Pune, Maharashtra"
              desc="Good platform to learn PTE. I highly prefer this platform for PTE, IELTS and also for TOEFL."
            />
          </div>
          <div className="k3study_ielets-rating-arrow">
            <img src={arrow1} alt="" />
            <img src={arrow2} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default IeletsRating;
