import React from 'react'
import './callexperts.css'
import { NavLink } from 'react-router-dom'
const CallExperts = () => {
  return (
    <div>
        <NavLink to="/contact-us" className="k3study_call-experts">
        <h2>Talk to our experts</h2>
        <button className="k3study_button">Call us now</button>
      </NavLink>
    </div>
  )
}

export default CallExperts
