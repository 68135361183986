import React from "react";
import "./benefits.css";
import BenefitsData from "../../../Data/BenefitsData";

const Benefits = () => {
  return (
    <div className="k3study-benefits_main">
      <div className="k3study-benefits_title">
        Why choose<span className="k3study-benefits-blue"> K3study</span>
      </div>
      <div className="k3study-benefits_content">
        {BenefitsData.map((item) => (
          <div key={item.id} className="k3study-benefits-sections">
            <img src={item.img} className="k3study_benefits-img" alt="" />
            <p className="k3study-benefits-text">{item.text}</p>
            <div className="k3study-benefits_hover-desc">
              <p className="k3study-benefits-text">{item.text}</p>
              <p className="k3study-benefits-desc">{item.desc}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Benefits;
