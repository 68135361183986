import React from "react";
import StudentReview from "../Components/Student Reviews/StudentReview";
import Contact from "../Components/Landing Page Components/Contact/Contact";
import AboutToelf from "../Components/Toelf Components/AboutToelf/AboutToelf";
import ToelfTraining from "../Components/Toelf Components/Toelf Training/ToelfTraining";
import Hero from "../Common/Hero/Hero";
import mainimg from "../Assets/Images/Pte/main.png";
import { useEffect } from "react";

const ToeflPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className="k3study_hero-counter">
        <Hero
          img={mainimg}
          title="Online TOEFL course"
          paragraph="Our TOEFL coaching institute offers comprehensive training for the Test of English as a Foreign Language. We provide personalized guidance and practice tests to ensure success in the exam. Our experienced instructors provide lessons covering all areas of the test, including reading, writing, listening, and speaking. With our proven techniques and strategies, we have helped thousands of students achieve their education and career goals."
        />
      </div>
      <AboutToelf />
      <ToelfTraining />
      <StudentReview />
      <Contact />
    </div>
  );
};

export default ToeflPage;
