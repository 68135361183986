import React from "react";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from "./Common/Navbar/Navbar";
import Header from "./Common/Header/Header";
import LandingPage from "./Pages/LandingPage";
import IeletsPage from "./Pages/IeletsPage";
import Footer from "./Common/Footer/Footer";
import ToeflPage from "./Pages/ToeflPage";
import OetPage from "./Pages/OetPage";
import GmatPage from "./Pages/GmatPage";
import SatPage from "./Pages/SatPage";
import ContactPage from "./Pages/ContactPage";
import TestimonialPage from "./Pages/TestimonialPage";
import ITrecruit from "./Pages/ITrecruit";
import Jobrecruit from "./Pages/job-recruit/Jobrecruit";
import AboutPage from "./Pages/AboutPage";
import PtePage from "./Pages/PtePage";
import CanadaPR from "./Pages/CanadaPR";

const App = () => {
  return (
    <div>
      <BrowserRouter>
        <Header />
        <Navbar />
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/about-us" element={<AboutPage />} />
          <Route path="/ielets" element={<IeletsPage />} />
          <Route path="/pte" element={<PtePage />} />
          <Route path="/toefl" element={<ToeflPage />} />
          <Route path="/oet" element={<OetPage />} />
          <Route path="/gmat" element={<GmatPage />} />
          <Route path="/sat" element={<SatPage />} />
          <Route path="/canada-pr" element={<CanadaPR />} />
          <Route path="/itrecruit" element={<ITrecruit />} />
          <Route path="/job-details" element={<Jobrecruit />} />
          <Route path="/testimonial" element={<TestimonialPage />} />
          <Route path="/contact-us" element={<ContactPage />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
};

export default App;
