import React, { useState } from "react";
import { NavLink, Link } from "react-router-dom";
import "./navbar.css";
import { GoThreeBars } from "react-icons/go";
import { IoIosArrowDown } from "react-icons/io";
import { BsXLg } from "react-icons/bs";
import logo from "../../Assets/Images/navbar/Logo.png";

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [toggleDropdown, setToggleDropdown] = useState(false);

  return (
    <div className="k3study__navbar">
      <div className="k3study__navbar-links">
        <div className="k3study__navbar-links_logo_container">
          <Link to="/" className="k3study__navbar_logo">
            <img src={logo} alt="" />
          </Link>
        </div>
        <div className="k3study__navbar-links_container">
          <li className="k3study__navbar-links_li">
            <NavLink to="/">Home</NavLink>
          </li>
          <li className="k3study__navbar-links_li">
            <NavLink to="/about-us">About Us</NavLink>
          </li>
          <div className="k3study__navbar-dropdown_li">
            <p className="k3study_navbar_dropdown_title">
              Trainings
              <IoIosArrowDown className="k3study_navbar_dropdown_arrow" />
            </p>
            <div className="k3study_navbar_dropdown-links">
              <NavLink className="k3study__navbar-dropdown-link" to="/ielets">
                IELTS
              </NavLink>
              <NavLink className="k3study__navbar-dropdown-link" to="/pte">
                PTE
              </NavLink>
              <NavLink className="k3study__navbar-dropdown-link" to="/toefl">
                TOEFL
              </NavLink>
              <NavLink className="k3study__navbar-dropdown-link" to="/gmat">
                GMAT
              </NavLink>
              <NavLink className="k3study__navbar-dropdown-link" to="/sat">
                SAT
              </NavLink>
              <NavLink className="k3study__navbar-dropdown-link" to="/oet">
                OET
              </NavLink>
            </div>
          </div>
          <li className="k3study__navbar-links_li">
            <NavLink to="/canada-pr">Canada PR</NavLink>
          </li>
          <li className="k3study__navbar-links_li">
            <NavLink to="/itrecruit">K3StudyRecruit</NavLink>
          </li>
          
          <li className="k3study__navbar-links_li">
            <NavLink to="/contact-us">Contact Us</NavLink>
          </li>
        </div>
      </div>
      <div className="k3study__navbar-menu">
        {toggleMenu ? (
          <BsXLg
            className="k3study__navbar-menu_toggleBtn"
            color="var(--color-text)"
            size={20}
            onClick={() => setToggleMenu(false)}
          />
        ) : (
          <GoThreeBars
            className="k3study__navbar-menu_toggleBtn"
            color="var(--color-text)"
            size={25}
            onClick={() => setToggleMenu(true)}
          />
        )}
        {toggleMenu && (
          <div className="k3study__navbar-menu_container">
            <div className="k3study__navbar-menu_container-links">
              <li
                onClick={() => {
                  setToggleMenu(false);
                  setToggleDropdown(false);
                }}
              >
                <NavLink to="/">Home</NavLink>
              </li>
              <li
                onClick={() => {
                  setToggleMenu(false);
                  setToggleDropdown(false);
                }}
              >
                <NavLink to="/about-us">About Us</NavLink>
              </li>
              <div className="k3study__navbar-dropdown_li">
                <p
                  className="k3study_navbar_dropdown_title"
                  onClick={() => setToggleDropdown(!toggleDropdown)}
                >
                  Trainings
                  <IoIosArrowDown
                    className="k3study_navbar_dropdown_arrow"
                    style={
                      toggleDropdown
                        ? { transform: "rotate(180deg)", color: "#2968E9" }
                        : ""
                    }
                  />
                </p>
                {/* {toggleDropdown && ( */}
                <div
                  className="k3study_navbar_dropdown-links"
                  style={toggleDropdown ? { height: "15rem" } : { height: 0 }}
                >
                  <li
                    onClick={() => {
                      setToggleMenu(false);
                      setToggleDropdown(false);
                    }}
                  >
                    <NavLink to="/ielets">IELTS</NavLink>
                  </li>
                  <li
                    onClick={() => {
                      setToggleMenu(false);
                      setToggleDropdown(false);
                    }}
                  >
                    <NavLink to="/pte">PTE</NavLink>
                  </li>
                  <li
                    onClick={() => {
                      setToggleMenu(false);
                      setToggleDropdown(false);
                    }}
                  >
                    <NavLink to="/toefl">TOEFL</NavLink>
                  </li>
                  <li
                    onClick={() => {
                      setToggleMenu(false);
                      setToggleDropdown(false);
                    }}
                  >
                    <NavLink to="/gmat">GMAT</NavLink>
                  </li>
                  <li
                    onClick={() => {
                      setToggleMenu(false);
                      setToggleDropdown(false);
                    }}
                  >
                    <NavLink to="/sat">SAT</NavLink>
                  </li>
                  <li
                    onClick={() => {
                      setToggleMenu(false);
                      setToggleDropdown(false);
                    }}
                  >
                    <NavLink to="/oet">OET</NavLink>
                  </li>
                </div>
                {/* )} */}
              </div>
              <li
                onClick={() => {
                  setToggleMenu(false);
                  setToggleDropdown(false);
                }}
              >
                <NavLink to="/canada-pr">Canada PR</NavLink>
              </li>
              <li
                onClick={() => {
                  setToggleMenu(false);
                  setToggleDropdown(false);
                }}
              >
            <NavLink to="/itrecruit">K3StudyRecruit</NavLink>
          </li>
              <li
                onClick={() => {
                  setToggleMenu(false);
                  setToggleDropdown(false);
                }}
              >
                <NavLink to="/contact-us">Contact Us</NavLink>
              </li>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;
