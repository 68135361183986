import pic1 from "../Assets/Images/Testimonials/pic1.png";
import pic2 from "../Assets/Images/Testimonials/pic2.png";
import pic3 from "../Assets/Images/Testimonials/pic3.png";
import star from "../Assets/Images/Testimonials/star.png";

const TestimonialData = [
  {
    id: 1,
    img: <img src={pic1} className="k3study_testimonial-img" alt="" />,
    name: "Mahesh Karna",
    role: "Student",
    stars: (
      <div className="k3study_testimonial_stars">
        <img src={star} alt="" />
        <img src={star} alt="" />
        <img src={star} alt="" />
        <img src={star} alt="" />
      </div>
    ),
    desc: "I would like to thank K3Study. I can say K3Study is one of the best institutes for IELTS coaching.",
  },
  {
    id: 2,
    img: <img src={pic2} className="k3study_testimonial-img" alt="" />,
    name: "Akul Reddy",
    role: "Student",
    stars: (
      <div className="k3study_testimonial_stars">
        <img src={star} alt="" />
        <img src={star} alt="" />
        <img src={star} alt="" />
        <img src={star} alt="" />
      </div>
    ),
    desc: "Trainers are very good at teaching and taking care of each and every student.",
  },
  {
    id: 3,
    img: <img src={pic3} className="k3study_testimonial-img" alt="" />,
    name: "Maha lakshmi",
    role: "Student",
    stars: (
      <div className="k3study_testimonial_stars">
        <img src={star} alt="" />
        <img src={star} alt="" />
        <img src={star} alt="" />
        <img src={star} alt="" />
      </div>
    ),
    desc: "Good platform to learn PTE. I highly prefer this platform for PTE, IELTS and also for TOEFL.",
  },
];
export default TestimonialData;
