import React from "react";
import "./contact.css";
// import skyblue from "../../../Assets/Images/Contact/skyblue2.png";
import call from "../../../Assets/Images/Contact/call.png";
import { NavLink } from "react-router-dom";
const Contact = () => {
  return (
    <div className="k3study_contact_main">
      <div className="k3study_contact_text">
        <p>Do you want to speak with our experts</p>
      </div>
      <div className="k3study_contact_img">
        <NavLink to="/contact-us" className="k3study_contact-contact">
          <img src={call} alt="" />
          <p className="k3study-explore-blue">Contact Us</p>
        </NavLink>
      </div>
    </div>
  );
};
const DemoContact = () => {
  return (
    <div className="k3study_contact_main">
      <div className="k3study_contact_text">
        <p>Attend your Demo Class and see for yourself</p>
      </div>
      <div className="k3study_contact_img">
        <NavLink to="/contact-us" className="k3study_contact-contact">
          <img src={call} alt="" />
          <p className="k3study-explore-blue">Contact Us</p>
        </NavLink>
      </div>
    </div>
  );
};

export default Contact;
export { DemoContact };
