import React from "react";
import "./header.css";

import fb from "../../Assets/Images/navbar/facebook.png";
import insta from "../../Assets/Images/navbar/instagram.png";
import email from "../../Assets/Images/navbar/email.png";
import call from "../../Assets/Images/navbar/call.png";
import yt from "../../Assets/Images/navbar/youtube.png";

const Header = () => {
  return (
    <div className="k3study__header">
      <div className="k3study__header-links_container">
        <div className="k3study__header_phone">
          <img className="k3study__header_icons" src={call} alt="" />
          <li className="k3study__header_phone-number">8341015060</li>
        </div>
      </div>
      <div className="k3study__header-links_container">
        <div className="k3study__header_phone">
          <img className="k3study__header_icons" src={email} alt="" />
          <li className="k3study__header_phone-number">info@K3study.com</li>
        </div>
        <div className="k3study__header-links_social">
          <a target="_blank" rel="noreferrer" href="http://www.fb.com">
            <img className="k3study__header_icons" src={fb} alt="" />
          </a>
          <a target="_blank" rel="noreferrer" href="http://www.youtube.com">
            <img className="k3study__header_icons" src={yt} alt="" />
          </a>
          <a target="_blank" rel="noreferrer" href="http://www.instagram.com">
            <img className="k3study__header_icons" src={insta} alt="" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Header;
