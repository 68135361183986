import React from "react";
import "./herocarousal.css";
import bigbtn from "../../../Assets/Images/Hero/bigbtn.png";
import smallbtn from "../../../Assets/Images/Hero/smallbtn.png";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import HeroData from "../../../Data/HeroData";
import { Carousel } from "react-responsive-carousel";
import EnquiryForm from "../../../Common/EnquiryForm/EnquiryForm";
import { NavLink } from "react-router-dom";

const HeroCarousal = () => {
  return (
    <div className="k3study_hero-carousal-component-main">
      {HeroData.map((item) => (
        <div key={item.id}>
          {item.id === 1 ? (
            <Carousel
              autoPlay
              infiniteLoop
              showThumbs={false}
              showStatus={false}
              preventMovementUntilSwipeScrollTolerance={true}
              swipeScrollTolerance={50}
              renderIndicator={(onClickHandler, isSelected, index, label) => {
                const img = isSelected ? (
                  <img
                    className="k3study_hero-carousal_carousel_indicator-selected"
                    style={{
                      width: "auto",
                      height: "auto",
                      marginBlock: "auto",
                      paddingInline: "0.2rem",
                      border: "1px solid transparent",
                    }}
                    src={bigbtn}
                    alt=""
                  />
                ) : (
                  <img
                    className="k3study_hero-carousal_carousel_indicators"
                    style={{
                      width: "auto",
                      height: "auto",
                      paddingInline: "0.2rem",
                    }}
                    src={smallbtn}
                    alt=""
                  />
                );
                return (
                  <span
                    onClick={onClickHandler}
                    onKeyDown={onClickHandler}
                    value={index}
                    key={index}
                    role="button"
                    tabIndex={0}
                    aria-label={`${label} ${index + 1}`}
                  >
                    {img}
                  </span>
                );
              }}
            >
              <div>
                {item.img1}
                <div className="k3study_hero-carousal-component_carousal_main">
                  <div className="k3study_hero-carousal-component_carousal-content">
                    <h2 className="k3study_hero-carousal-component_heading">
                      {item.text1}
                    </h2>
                    <p className="k3study_hero-carousal-component_para">
                      Our experienced instructors will create a personalized
                      learning plan that caters to your unique needs and goals.
                      With our help, you will gain a comprehensive understanding
                      of the material and develop test-taking skills that will
                      help you succeed on any exam.
                    </p>
                    <div className="k3study_hero-carousal-component_carousal_content_btns">
                      <NavLink to="/contact-us">
                        <button className="k3study_hero-carousal-component_all-course-btn">
                          {item.btntxt}
                        </button>
                      </NavLink>
                    </div>
                  </div>
                  <EnquiryForm />
                </div>
              </div>
              <div>
                {item.img2}
                <div className="k3study_hero-carousal-component_carousal_main">
                  <div className="k3study_hero-carousal-component_carousal-content">
                    <h2 className="k3study_hero-carousal-component_heading">
                      Get the Grade You Deserve
                    </h2>
                    <p className="k3study_hero-carousal-component_para">
                      K3 Study provides comprehensive tutoring services tailored
                      to each individual’s needs. We offer a variety of study
                      materials that range from online classes to one-on-one
                      in-person sessions. Our experienced instructors are here
                      to help you get the grade you deserve!
                    </p>
                  </div>
                </div>
              </div>
              <div>
                {item.img3}
                <div className="k3study_hero-carousal-component_carousal_main">
                  <div className="k3study_hero-carousal-component_carousal-content">
                    <h2 className="k3study_hero-carousal-component_heading">
                      Expert Guidance for Every Step of Your Journey
                    </h2>
                    <p className="k3study_hero-carousal-component_para">
                      At K3 Study, our team is committed to helping you achieve
                      success in your academic endeavors. With our expert
                      guidance and personalized learning plans, we can help you
                      reach your goals with confidence!
                    </p>
                  </div>
                </div>
              </div>
            </Carousel>
          ) : (
            ""
          )}
        </div>
      ))}
    </div>
  );
};

export default HeroCarousal;
