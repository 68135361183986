import React from "react";
import "./aboutsat.css";
import tick from "../../../Assets/Images/SAT/tick.png";
const AboutSat = () => {
  return (
    <div className="k3study_about-sat_main">
      <h2 className="k3study_heading">What is SAT?</h2>
      <p>
        The SAT is a standardized college entrance exam accepted by many college
        admissions offices around the world. It is designed to measure academic
        aptitude and predict academic success in college. The SAT covers
        reading, writing, and mathematics and assesses a student's
        problem-solving,
        <span className="k3study_about-sat-span">
          analytical thinking, and writing skills.
        </span>
      </p>
      <h2 className="k3study_heading">
        Why choose K3 study SAT coaching class ?
      </h2>
      <p>
        K3 Study's SAT training class is an excellent choice for those looking
        to significantly improve their SAT scores. With experienced and
        knowledgeable instructors, engaging resources, and personalized learning
        plans, K3 Study helps students effectively prepare for the SAT. The
        course helps students master difficult concepts and hone their
        test-taking skills, so they can maximize their time on the exam, and get
        the score they want. Additionally, K3 Study offers practice tests and
        material reviews, which provide personalized feedback and crucial
        preparation for the SAT. With K3 Study, students can unlock their full
        academic potential and achieve top-tier scores.
      </p>
      <div className="k3study_about-sat-list">
        <ul>
          <li className="k3study_about-gmat_li">The best faculties</li>
          <li className="k3study_about-gmat_li">Never-ending support</li>
          <li className="k3study_about-gmat_li">24/7 test mode</li>
          <li className="k3study_about-gmat_li">
            Unlimited doubt sessions across phone, Zoom or in-person visit
            across our centres
          </li>
          <li className="k3study_about-gmat_li">
            The best results with the least of efforts
          </li>
        </ul>
      </div>
      <h2 className="k3study_heading">How to prepare for SAT</h2>
      <div className="k3study_about-sat-list-item">
        <img src={tick} alt="" />
        <p>
          Begin by familiarizing yourself with the test structure. Understand
          the sections, how long the test takes, and the types of questions you
          will encounter.
        </p>
      </div>
      <div className="k3study_about-sat-list-item">
        <img src={tick} alt="" />
        <p>
          Use online resources and practice tests to build your skills in the
          math, reading, and writing sections of the test.
        </p>
      </div>
      <div className="k3study_about-sat-list-item">
        <img src={tick} alt="" />
        <p>
          Make sure to study in advance rather than cramming right before the
          test date.
        </p>
      </div>
      <div className="k3study_about-sat-list-item">
        <img src={tick} alt="" />
        <p>
          Attend an SAT prep class or work with a tutor to learn test-taking
          strategies and helpful tips for cracking the code of the test.
        </p>
      </div>
      <div className="k3study_about-sat-list-item">
        <img src={tick} alt="" />
        <p>
          Secure books, flashcards, and apps to help you practice math, grammar,
          and vocabulary.
        </p>
      </div>
      <div className="k3study_about-sat-list-item">
        <img src={tick} alt="" />
        <p>
          On the day of the test, get plenty of rest and eat a healthy breakfast
        </p>
      </div>
      <div className="k3study_about-sat-list-item">
        <img src={tick} alt="" />
        <p>
          Arrive to the test early, as you may face delays due to traffic or
          other unforeseen circumstances.
        </p>
      </div>
      <div className="k3study_about-sat-list-item">
        <img src={tick} alt="" />
        <p>Finally, keep positive and believe in yourself!</p>
      </div>
    </div>
  );
};

export default AboutSat;
