import React from "react";
import "./aboutoet.css";
import healthcare1 from "../../../Assets/Images/Oet/healthcare1.png";
import healthcare2 from "../../../Assets/Images/Oet/healthcare2.png";
import graph from "../../../Assets/Images/Oet/graph.png";
import img1 from "../../../Assets/Images/Oet/img1.png";
import img2 from "../../../Assets/Images/Oet/img2.png";
import img3 from "../../../Assets/Images/Oet/img3.png";
import img4 from "../../../Assets/Images/Oet/img4.png";
import img5 from "../../../Assets/Images/Oet/img5.png";
import img6 from "../../../Assets/Images/Oet/img6.png";
const AboutOet = () => {
  return (
    <div className="k3study_about-oet">
      <p className="k3study_about-oet-paragraph">
        Occupational English Test is the English Language Test for Health care
        professionals. The Occupational English Test is designed to test the
        English proficiency of healthcare professionals who aspire to work or
        practice in English-speaking countries. OET exam has been conducted by
        CBLA or Cambridge Boxhill Language Assessment which is a venture between
        Cambridge English and Boxhill Institute.
      </p>
      <div className="k3study_about-oet-main">
        <div className="k3study_about-oet-section1">
          <h2 className="k3study_about-oet-heading">
            Connecting the world
            <br />
            to Better
            <span className="k3study_text-blue"> HealthCare</span>
          </h2>
          <p>
            Successful OET learners have the English language and clinical
            communication skills to provide high quality and safe patient care.
          </p>
          <p>That means better healthcare for all.</p>
        </div>
        <div className="k3study_about-oet-section2">
          <img
            className="k3study_about-oets-healthcare-img1"
            src={healthcare1}
            alt=""
          />
          <img
            className="k3study_about-oets-healthcare-img2"
            src={healthcare2}
            alt=""
          />
        </div>
      </div>
      <div className="k3study_why-oets">
        <h2>Why OET?</h2>
        <p className="k3study_why-oets-paragraph">
          1. OET is the only test that is specific to healthcare, and accepted
          worldwide.
        </p>
        <p className="k3study_why-oets-paragraph">
          2.By taking OET, candidate will prove that he has the right level of
          English.
        </p>
        <p className="k3study_why-oets-paragraph">
          3.OET is widely accepted by Government bodies, Organizations,
          Universities in Australia, New Zealand, UK, Singapore, Dubai, Ukraine,
          Namibia etc...
        </p>
      </div>
      <div className="k3study_graph-main">
        <img className="k3study_why-oets-graph" src={graph} alt="" />
        <div className="k3study_why-oets-main-content">
          <div className="k3study_why-oets-content">
            <img className="k3study_graph-main_map-img" src={img1} alt="" />
            <h3 className="k3study_why-oets-content-text">Australia</h3>
          </div>
          <div className="k3study_why-oets-content">
            <img className="k3study_graph-main_map-img" src={img2} alt="" />
            <h3 className="k3study_why-oets-content-text">Canada</h3>
          </div>
          <div className="k3study_why-oets-content">
            <img className="k3study_graph-main_map-img" src={img3} alt="" />
            <h3 className="k3study_why-oets-content-text">Ireland</h3>
          </div>
          <div className="k3study_why-oets-content">
            <img className="k3study_graph-main_map-img" src={img4} alt="" />
            <h3 className="k3study_why-oets-content-text">Singapore</h3>
          </div>
          <div className="k3study_why-oets-content">
            <img className="k3study_graph-main_map-img" src={img5} alt="" />
            <h3 className="k3study_why-oets-content-text">UK</h3>
          </div>
          <div className="k3study_why-oets-content">
            <img className="k3study_graph-main_map-img" src={img6} alt="" />
            <h3 className="k3study_why-oets-content-text">USA</h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutOet;
