import React from "react";
import "./contactcard.css";
import location from "../../../Assets/Images/Contact/location.png";
import whitecall from "../../../Assets/Images/Contact/whitecall.png";
import mail from "../../../Assets/Images/Contact/mail.png";
import insta from "../../../Assets/Images/Contact/insta.png";
import facebook from "../../../Assets/Images/Contact/facebook.png";
import twitter from "../../../Assets/Images/Contact/twitter.png";
import youtube from "../../../Assets/Images/Contact/youtube.png";

const ContactCard = () => {
  return (
    <div className="k3study_contact">
      <h3 className="k3study_heading">Let us know how we can help you</h3>
      <div className="k3study_contact-card-main">
        <div className="k3study_contact-card-section1">
          <h4 className="k3study_contact-card-section1_title">
            Send a Message
          </h4>
          <div className="k3study_contact-card-inputs">
            <div className="k3study_contact-card-name">
              <h6>Full name</h6>
              <input className="k3study_contact-card-input" type="text" />
            </div>
            <div className="k3study_contact-card-lname">
              <h6>Last name</h6>
              <input className="k3study_contact-card-input" type="text" />
            </div>
          </div>
          <div className="k3study_contact-card-inputs">
            <div className="k3study_contact-card-email">
              <h6>Email Address</h6>
              <input className="k3study_contact-card-email-input" type="text" />
            </div>
          </div>
          <div className="k3study_contact-card-inputs">
            <div className="k3study_contact-card-message">
              <h6>Message</h6>
              <textarea rows="4" className="k3study_contact-card-input" />
            </div>
          </div>
          <button className="k3study_contact-card-button">Submit</button>
        </div>
        <div className="k3study_contact-card-section2">
          <h2 className="k3study_contact-card-contact-info">CONTACT INFO</h2>
          <div className="k3study_contact-card_email-phone">
            <img src={whitecall} alt="" />
            <p className="k3study_contact-card_details">+91-8341015060</p>
          </div>
          <div className="k3study_contact-card_email-phone">
            <img src={mail} alt="" />
            <p className="k3study_contact-card_details">info@K3study.com</p>
          </div>
          <div className="k3study_contact-card-location-input">
            <img src={location} alt="" />
            <div className="k3study_contact-card_location">
              <p className="k3study_contact-card_details">Coaching Center :</p>
              <p className="k3study_contact-card_desc">
                
              </p>
            </div>
          </div>
          <div className="k3study_contact-card-icons">
            <img src={insta} alt="" />
            <img src={facebook} alt="" />
            <img src={twitter} alt="" />
            <img src={youtube} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactCard;
