import React from "react";
import CallExperts from "../Common/CallExperts/CallExperts";
import IeletsCards from "../Components/Ielets Components/Ielets Cards/IeletsCards";
import IeletsList from "../Components/Ielets Components/Ielets List/IeletsList";
import StudentReview from "../Components/Student Reviews/StudentReview";
import WhatIsIelets from "../Components/Ielets Components/WhatIsIelets/WhatIsIelets";
// import Contact from "../Components/Landing Page Components/Contact/Contact";
import Hero from "../Common/Hero/Hero";
import main from "../Assets/Images/Ielets/main.png";
import { useEffect } from "react";

const IeletsPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className="k3study_hero-counter">
        <Hero img={main} title="Online IELTS course" paragraph=" At our IELTS coaching institute, we help students prepare for the International English Language Testing System. Our experienced instructors provide personalized guidance and practice tests to ensure high scores and success. With our proven techniques and strategies, we have helped thousands of students achieve their education and career goals"/>
      </div>
      <WhatIsIelets />
      <IeletsCards />
      <IeletsList />
      <StudentReview />
      <CallExperts />
    </div>
  );
};

export default IeletsPage;
