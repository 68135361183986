import React from 'react'
import './cards.css'
const RedCards = (props) => {
  return (
    <div className='k3study-red-card'>
      <h2 className='k3study-red-card-heading'>{props.heading}</h2>
      <p className='k3study-paragraph'>{props.paragraph}</p>
      <button className='k3study-red-card-button'>Learn more</button>
    </div>
  )
}
const PurpleCards = (props) => {
    return (
      <div className='k3study-purple-card'>
        <h2 className='k3study-purple-card-heading'>{props.heading}</h2>
        <p className='k3study-paragraph'>{props.paragraph}</p>
        <button className='k3study-purple-card-button'>Learn more</button>
      </div>
    )
  }
  const YellowCards = (props) => {
    return (
      <div className='k3study-yellow-card'>
        <h2 className='k3study-yellow-card-heading'>{props.heading}</h2>
        <p className='k3study-paragraph'>{props.paragraph}</p>
        <button className='k3study-yellow-card-button'>Learn more</button>
      </div>
    )
  }
  const WhiteCards = (props) => {
    return (
      <div className='k3study-white-card'>
        <h2 className='k3study-white-card-heading'>{props.heading}</h2>
        <p className='k3study-paragraph'>{props.paragraph}</p>
        <button className='k3study-white-card-button'>Learn more</button>
      </div>
    )
  }
    

export default RedCards
export {YellowCards,PurpleCards,WhiteCards}
