import React from "react";
import { WhiteCards } from "../../../Common/Cards/Cards";
import "./ieletscards.css";

const IeletsCards = () => {
  return (
    <div>
      <div className="k3study_ieletscards-main">
        <WhiteCards
          heading="Study"
          paragraph="IELTS scores shows your English language proficiency, which is required for study abroad. It helps universities to measure a student's ability to communicate in an academic environment
          ."
        />
        <WhiteCards
          heading="Migration"
          paragraph="IELTS score can be used to meet migration requirements. Higher the score, better the chance of obtaining visa and migrating to the desired country
          ."
        />
        <WhiteCards
          heading="Work "
          paragraph="IELTS scores can demonstrate your language proficiency, giving employers confidence in your ability to communicate on the job - an essential requirement in many roles."
        />
      </div>
      <div className="k3study_get-quotes">
        <h2 className="k3study_quote_text">
          Get Ielts exam preparation plan for our certified trainers.
        </h2>
        <h2 className="k3study_quote_btn">GET QUOTE</h2>
      </div>
    </div>
  );
};

export default IeletsCards;
