import React from "react";
import "./whatisielets.css";

const WhatIsIelets = () => {
  return (
    <div className="k3study_what-is-iletes_main">
      <h1>What is IELTS?</h1>
      <p>
        IELTS (International English Language Testing System) is an English
        language proficiency assessment used in academic and professional
        contexts. It is the world's most popular English language test, used in
        over 140 countries. With IELTS, individuals can demonstrate their
        ability to understand and communicate effectively in English, in both
        academic and professional contexts. The test is split into four
        components: Listening, Reading, Writing and Speaking. Each of these
        components is assessed on a 9-band scale, with 0 being the lowest, and 9
        being the highest. Candidates must score a minimum of 6 in each
        component to achieve an overall pass.
      </p>
      <h1>Why take IELTS?</h1>
      <p>
        1. International Recognition: IELTS is accepted by over 9,000
        organizations globally which means successful test takers can open
        international career paths.
      </p>
      <p>
        2. Exam Security: IELTS is known for its strict security measures which
        are implemented across test centers globally to ensure fairness.
      </p>
      <p>
        3. Updated Content: The IELTS test is constantly updaed to reflect the
        language use in the real world.
      </p>
      <p>
        4. Flexible Test Schedules: With diverse test centers, test takers can
        choose to take the test at a time and location of their choice.
      </p>
      <p>
        5. High Score Accuracy: The test is designed to provide an assessment of
        the language abilities of test takers which are deemed to be accurate.
      </p>
      <p>
        6. Study Support: Test takers are provided with a variety of study
        materials to help them prepare for the IELTS exam.
      </p>
      <p>
      7. Achievable IELTS Score: With focused test preparation and commitment, test takers can attain their desired score.
      </p>
      <h1>How IELTS Score can help you</h1>
      <p>
        Regardless of whether you are applying for study, work or a visa, the
        IELTS assessment is the equivalent as far as content, analysis, test
        structure, and level of scoring evaluation. If your results are not
        satisfactory, there is no restriction limit on when and how regularly
        you can retake it. However, it needs your time investment and a lot of
        practice before you crack the IELTS test.
      </p>
    </div>
  );
};

export default WhatIsIelets;
