import React from "react";

const EnquiryForm = () => {
  return (
    <div className="k3study_hero-carousal-component_carousal_enquiry-form">
      <div className="k3study_hero-carousal-component_enquiry-form_div">
        <p className="k3study_hero-carousal-component_enquiry-form_title">
          Enquiry Now
        </p>
        <div className="k3study_hero-carousal-component_enquiry-form_inputs">
          <p className="k3study_hero-carousal-component_enquiry-form_desc">
            Please fill the form, we will get back to you ASAP!
          </p>
          <input
            className="k3study_hero-carousal-component_enquiry-form_input"
            type="text"
            placeholder="Your Full Name"
          />
          <input
            className="k3study_hero-carousal-component_enquiry-form_input"
            type="text"
            placeholder="Your Email Address"
          />
          <input
            className="k3study_hero-carousal-component_enquiry-form_input"
            type="text"
            placeholder="Mobile Number"
          />
          <textarea
            className="k3study_hero-carousal-component_enquiry-form_input"
            rows="3"
            placeholder="Message"
          />
          <p className="k3study_hero-carousal-component_enquiry-form_desc">
            *Your details will be kept confidential
          </p>
          <button className="k3study_hero-carousal-component_submit-btn">
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default EnquiryForm;
