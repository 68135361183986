import carouselImg1 from "../Assets/Images/Hero/carousel1.png";
import play from "../Assets/Images/Hero/play.png";
import carouselImg2 from "../Assets/Images/Hero/carousel2.png";
import carouselImg3 from "../Assets/Images/Hero/carousel3.png";
import main from "../Assets/Images/Ielets/main.png";
import mainpte from "../Assets/Images/Pte/main.png";
import mainimg from "../Assets/Images/Oet/mainimg.png";
import satmain from "../Assets/Images/SAT/satmain.png";
import contactmain from "../Assets/Images/Contact/contactmain.png";
import mainabout from "../Assets/Images/About/mainabout.png";
const HeroData = [
  {
    id: 1,
    img1: <img className="k3study_carousel_img" src={carouselImg1} alt="" />,
    img2: <img className="k3study_carousel_img" src={carouselImg2} alt="" />,
    img3: <img className="k3study_carousel_img" src={carouselImg3} alt="" />,
    text1: "Achieve Your Goals with Confidence",
    paragraph:
      "Our experienced instructors will create a personalized learning plan that caters to your unique needs and goals. With our help, you will gain a comprehensive understanding of the material and develop test-taking skills that will help you succeed on any exam. ",
    btntxt: "Get Started",
    play: <img src={play} alt="" />,
    watch: "Watch Video",
  },
  {
    id: 2,
    img1: (
      <img
        className="k3study_carousel_img k3study_hero_bg-img"
        src={main}
        alt=""
      />
    ),
    text1: "Get the Grade You Deserve",
    paragraph:
      "K3 Study provides comprehensive tutoring services tailored to each individual’s needs. We offer a variety of study materials that range from online classes to one-on-one in-person sessions. Our experienced instructors are here to help you get the grade you deserve!",
  },
  {
    id: 3,
    img1: (
      <img
        className="k3study_carousel_img k3study_hero_bg-img"
        src={mainpte}
        alt=""
      />
    ),
    text1: "Expert Guidance for Every Step of Your Journey",
    paragraph:
      "At K3 Study, our team is committed to helping you achieve success in your academic endeavors. With our expert guidance and personalized learning plans, we can help you reach your goals with confidence!",
  },
  {
    id: 4,
    img1: (
      <img
        className="k3study_carousel_img k3study_hero_bg-img"
        src={mainpte}
        alt=""
      />
    ),
    text1: "Online TOEFL course",
    paragraph:
      "Prepare for TOELF online with self-paced or one-to-one courses, and enjoy the IELTS writing correction and consultation services at an international language academy powered by Machine Learning",
  },
  {
    id: 5,
    img1: (
      <img
        className="k3study_carousel_img k3study_hero_bg-img"
        src={mainimg}
        alt=""
      />
    ),
    text1: "Online OET course",
    paragraph:
      "Prepare for PTE online with self-paced or one-to-one courses, and enjoy the IELTS writing correction and consultation services at an international language academy powered by Machine Learning",
  },
  {
    id: 6,
    img1: (
      <img
        className="k3study_carousel_img k3study_hero_bg-img"
        src={mainpte}
        alt=""
      />
    ),
    text1: "Online GMAT course",
    paragraph:
      "Prepare for GMAT online with self-paced or one-to-one courses, and enjoy the IELTS writing correction and consultation services at an international language academy powered by Machine Learning",
  },
  {
    id: 7,
    img1: (
      <img
        className="k3study_carousel_img k3study_hero_bg-img"
        src={satmain}
        alt=""
      />
    ),
    text1: "Online SAT course",
    paragraph:
      "Prepare for SAT online with self-paced or one-to-one courses, and enjoy the IELTS writing correction and consultation services at an international language academy powered by Machine Learning",
  },
  {
    id: 8,
    img1: (
      <img
        className="k3study_carousel_img k3study_hero_bg-img"
        src={contactmain}
        alt=""
      />
    ),
    text1: "Get in touch",
    paragraph:
      "Prepare for SAT online with self-paced or one-to-one courses, and enjoy the IELTS writing correction and consultation services at an international language academy powered by Machine Learning",
  },
  {
    id: 9,
    img1: (
      <img
        className="k3study_carousel_img k3study_hero_bg-img"
        src={satmain}
        alt=""
      />
    ),
    text1: "A lot of happy students ",
    paragraph:
      "Lorem ipsum dolor sit amet consectetur. In molestie euismod elit feugiat in nisi nulla. Lorem ipsum dolor sit amet consectetur. In molestie euismod elit feugiat in nisi nulla. ",
  },
  {
    id: 10,
    img1: (
      <img
        className="k3study_carousel_img k3study_hero_bg-img"
        src={mainabout}
        alt=""
      />
    ),
    text1: "About Us ",
    paragraph:
      "Lorem ipsum dolor sit amet consectetur. In molestie euismod elit feugiat in nisi nulla. Lorem ipsum dolor sit amet consectetur. In molestie euismod elit feugiat in nisi nulla. ",
  },
];
export default HeroData;
