import React from "react";
import "./footer.css";
import { BsInstagram, BsFacebook } from "react-icons/bs";
import { Link, NavLink } from "react-router-dom";

const Footer = () => {
  return (
    <div className="k3study__footer">
      <div className="k3study__footer-links">
        <div className="k3study__footer-links_logo_about_contact_container">
          <h1 className="k3study__footer-links_logo">K3Study</h1>
          <p className="k3study__footer-links_logo_about_text">
            We are the best institute for IELTS, PTE, TOEFL, OET, GMAT, and SAT
            coaching. Our mission is to provide world-class education and
            guidance to our students for achieving their academic goals.
          </p>
          <div className="k3study__footer-links_contact_container">
            <div className="k3study__footer-links_contact_container_phone_container">
              <h4 className="k3study__footer-links_contact_container_phone">
                Contact
              </h4>
              <p className="k3study__footer-links_contact_container_phone_number">
                Phone No: 8341015060
              </p>
            </div>
            <div className="k3study__footer-links_contact_container_email_container">
              <h4 className="k3study__footer-links_contact_container_email">
                Email ID
              </h4>
              <p className="k3study__footer-links_contact_container_emailId">
                <a href="info@k3study.com">info@K3study.com</a>
              </p>
            </div>
          </div>
          <div className="k3study__footer-links_social_container">
            <h4>FOLLOW US ON</h4>
            <div className="k3study__footer-links_contact_container_social_icons">
              <BsInstagram className="k3study__footer-links_contact_container_social_icon" />
              <BsFacebook className="k3study__footer-links_contact_container_social_icon" />
            </div>
          </div>
        </div>
        <div className="k3study__footer-course_container">
          <h3>TRAININGS</h3>
          <li className="k3study__footer_li">
            <Link to="/ielets">IELTS</Link>
          </li>
          <li className="k3study__footer_li">
            <Link to="/pte">PTE</Link>
          </li>
          <li className="k3study__footer_li">
            <Link to="/toefl">TOEFL</Link>
          </li>
          <li className="k3study__footer_li">
            <Link to="/gmat">GMAT</Link>
          </li>
          <li className="k3study__footer_li">
            <Link to="/sat">SAT</Link>
          </li>
          <li className="k3study__footer_li">
            <Link to="/oet">OET</Link>
          </li>
        </div>
        <div className="k3study__footer-newsletter_container">
          <h3>JOIN A NEWSLETTER</h3>
          <li className="k3study__footer_li">Your Email Id</li>
          <input
            className="k3study__footer-newsletter_input"
            type="email"
            placeholder="Enter your email id"
          />
        </div>
        <h3 className="k3study__footer-careers">CAREERS</h3>
      </div>
    </div>
  );
};

export default Footer;
