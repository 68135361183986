import React, { useEffect } from "react";
import "./IeletsTestimonialCard.css";
import star from "../../../Assets/Images/Testimonials/star.png";
import { FaUserCircle } from "react-icons/fa";

const IeletsTestimonialCard = (props) => {
  useEffect(() => {
    // window.scrollTo(0, 0);
  }, []);
  return (
    // <div className="k3study_ielets-testimonial-card-main">
    <div className="k3study_ielets-testimonial-card">
      <div className="k3study_ielets-testimonial-card_content">
        <FaUserCircle className="k3study_ielets-testimonial-card_user-img" />
        <p>{props.desc}</p>
        <div className="k3study_ielets-testimonial-card_lower-div">
          <div className="k3study_ielets-testimonial-card_user-details-div">
            <p className="k3study_ielets-testimonial-card_user-details_name">
              {props.name}
            </p>
            <p className="k3study_ielets-testimonial-card_user-details_adress">
              {props.adress}
            </p>
          </div>
          <div className="k3study_ielets-testimonial-card-stars">
            <img src={star} alt="" />
            <img src={star} alt="" />
            <img src={star} alt="" />
            <img src={star} alt="" />
            <img src={star} alt="" />
          </div>
        </div>
      </div>
      {/* </div> */}
    </div>
  );
};

export default IeletsTestimonialCard;
