import React from 'react'
import "./jobrecruit.css";
import lady from "../../Assets/jobcard/Inbox cleanup-pana (1) 1.png";

const Jobrecruit = () => {
    return (
        <div className="recruitment_jobdetail">
          <div className="recruitment_jobdetail_content">
            <p className="recruitment_jobdetail_title">Thank you for showing you Interest  </p>
            <p className="recruitment_jobdetail_desc">
            We’re happy to get your resume and we’ll be in touch  with you as soon as possible via email.
            Please send us your resume by email at 
            </p>
            <p className="recruitment_jobdetail_desc1">info@k3study.com</p>
          </div>
          <img className="recruitment_jobdetail_img" src={lady} alt="" />
        </div>
      );
    };

export default Jobrecruit;

