import React from "react";
import "./aboutbusiness.css";
import img1 from "../../../Assets/Images/About/img1.png";
import img2 from "../../../Assets/Images/About/img2.png";
import img3 from "../../../Assets/Images/About/img3.png";
import img4 from "../../../Assets/Images/About/img4.png";
import img5 from "../../../Assets/Images/About/img5.png";
import pic1 from "../../../Assets/Images/About/pic1.png";
import pic2 from "../../../Assets/Images/About/pic2.png";
import pic3 from "../../../Assets/Images/About/pic3.png";

import { FaUserCircle } from "react-icons/fa";

const AboutBusiness = () => {
  return (
    <div className="k3study_about-business">
      <div className="k3study_about-business-choose-us">
        <h2>Why Choose Us?</h2>
        <div className="k3study_about-business-choose-us_main">
          <div className="k3study_about-business-choose-us-section1">
            <img src={img4} alt="" />
            <h6>Support from expert team</h6>
            <p>
              Our Regional Managers and expert team at the head office are just
              a call/text away and eager to offer Support 24/7.
            </p>
          </div>
          <div className="k3study_about-business-choose-us-section1">
            <img src={img5} alt="" />
            <h6>Analysis</h6>
            <p>
              We Analyse Your Profile and accordingly we will apply for various interviews
              across india
            </p>
          </div>
          <div className="k3study_about-business-choose-us-section1">
            <img src={img5} alt="" />
            <h6>Interviews</h6>
            <p>
              We provide Placement all over india according to your profile. We also Provide 
              some mock interviews.
            </p>
          </div>
        </div>
      </div>
      
    </div>
  );
};

export default AboutBusiness;
