import React from "react";
import "./ieletslist.css";
import icon1 from "../../../Assets/Images/Ielets/icon1.png";
import icon2 from "../../../Assets/Images/Ielets/icon2.png";
import icon3 from "../../../Assets/Images/Ielets/icon3.png";
import icon4 from "../../../Assets/Images/Ielets/icon4.png";
import icon5 from "../../../Assets/Images/Ielets/icon5.png";
import icon6 from "../../../Assets/Images/Ielets/icon6.png";
import list from "../../../Assets/Images/Ielets/list.png";
const IeletsList = () => {
  return (
    <div>
      <div className="k3study_ieletslist-container">
      <h1>How does Job Placement work?</h1>
        
        </div>
      <div className="k3study_ieletslist-main">
        
        <div className="k3study_ieletslist">
          <div className="k3study_ieletslist-components">
            <div className="k3study_ieletslist-icon">
              <img src={icon1} alt="" />
            </div>
            <h4 className="k3study_ieletslist-text">
              Direct Hire
            </h4>
          </div>
          <div className="k3study_ieletslist-components">
            <div className="k3study_ieletslist-icon">
              <img src={icon2} alt="" />
            </div>
            <h4 className="k3study_ieletslist-text">
              Contact to Hire
            </h4>
          </div>
          <div className="k3study_ieletslist-components">
            <div className="k3study_ieletslist-icon">
              <img src={icon3} alt="" />
            </div>
            <h4 className="k3study_ieletslist-text">
              Temp to hire
            </h4>
          </div>
          <div className="k3study_ieletslist-components">
            <div className="k3study_ieletslist-icon">
              <img src={icon4} alt="" />
            </div>
            <h4 className="k3study_ieletslist-text">
              Part time & Full Time
            </h4>
          </div>
          <div className="k3study_ieletslist-components">
            <div className="k3study_ieletslist-icon">
              <img src={icon2} alt="" />
            </div>
            <h4 className="k3study_ieletslist-text">
              Freshers & Experience
            </h4>
          </div>
          
        </div>
        <div className="k3study_ieletslist-img">
          <img className="k3study_ieletslist-girl-img" src={list} alt="" />
        </div>
      </div>
    </div>
  );
};

export default IeletsList;
