import React from "react";
import ContactCard from "../Components/Contact Components/Contact Card/ContactCard";
import ContactMap from "../Components/Contact Components/Contact Map/ContactMap";
import contactmain from "../Assets/Images/Contact/contactmain.png";
import ContactHero from "../Components/Contact Components/ContactHero/ContactHero";
import { useEffect } from "react";

const ContactPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className="k3study_hero-counter">
        <ContactHero img={contactmain} title="Get in touch" />
      </div>
      <ContactCard />
      <ContactMap />
    </div>
  );
};

export default ContactPage;
