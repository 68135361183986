import React from "react";
import "./abouttoelf.css";
const AboutToelf = () => {
  return (
    <div className="k3study_about-pte_main">
      <h1>What is TOEFL?</h1>
      <p className="k3study_about-toelf-paragraph">
        Test of English as a Foreign Language (TOEFL) is a standardized test to
        measure the English language ability of non-native speakers wishing to
        enroll in English-speaking universities can approach K3study which
        conducts TOEFL coaching in Hyderabad. The test is accepted by many
        English-speaking academic and professional institutions. TOEFL is one of
        the two major English-language tests in the world, the other being the
        IELTS.
      </p>
      <p className="k3study_about-toelf-paragraph">
        TOEFL is a trademark of the Educational Testing Service (ETS), a private
        non-profit organization, which designs and administers the tests. ETS
        issues official score reports which are sent independently to
        institutions and are valid for two years following the test.
      </p>
      <p className="k3study_about-toelf-paragraph">
        The TOEFL test was first administered in 1964 by the Modern Language
        Association financed by grants from the Ford Foundation and Danforth
        Foundation. The Modern Language Association first conducted the TOEFL
        test in 1964.The College Board and ETS jointly assumed responsibility
        for the TOEFL testing program's continuation in 1965. The two formats
        introduced were Internet and paper based. Get to know the test patterns
        with K3study IELTS and TOEFL coaching in Hyderabad.
      </p>
    </div>
  );
};

export default AboutToelf;
