import React from "react";
import "./toelftraining.css";
import background from "../../../Assets/Images/Toelf/background.png";
const ToelfTraining = () => {
  return (
    <div className="k3study_toelf-training-main">
      <div className="k3study_toelf-training-background">
        <img className="k3study_toelf-training_img" src={background} alt="" />
      </div>
      <div className="k3study_toelf-training-content">
        <h2 className="k3study_toelf-training-heading1">4 weeks of training</h2>
        <h2 className="k3study_toelf-training-heading2">
          Daily session and mock test on weekends.
        </h2>
        <button className="k3study_toelf-training-button">
          Enroll for TOEFL online training
        </button>
      </div>
    </div>
  );
};

export default ToelfTraining;
