import React from "react";
import "./introduction.css";
// import yellow from "../../../Assets/Images/introduction/yellow.png";
// import play from "../../../Assets/Images/introduction/play.png";
import red from "../../../Assets/Images/introduction/red.png";
import lady from "../../../Assets/Images/introduction/lady.png";
import ladyBg from "../../../Assets/Images/introduction/ladyBg.png";
import bubble from "../../../Assets/Images/introduction/bubble.png";
import pinkbubble from "../../../Assets/Images/introduction/pinkbubble.png";
import chat from "../../../Assets/Images/introduction/chat.png";
import group10 from "../../../Assets/Images/introduction/group10.png";
import group20 from "../../../Assets/Images/introduction/group20.png";
import group300 from "../../../Assets/Images/introduction/group300.png";
import greenchat from "../../../Assets/Images/introduction/greenchat.png";

const Introduction = () => {
  return (
    <div>
      <div className="k3study-introduction-main">
        <div className="k3study-introduction-section1">
          <h5 className="k3study_introduction-who-we-are">WHO ARE WE</h5>
          <h2>
            About<span className="k3study-introduction-blue"> K3study</span>
          </h2>
          <p className="k3study-introduction-paragraph">
            We are a professional coaching institute providing guidance and
            assistance for students willing to excel in IELTS, PTE, TOEFL, OET,
            GMAT, and SAT. Our experienced and certified team of experts is
            committed to helping students reach their goals and excel in these
            exams. With our comprehensive study materials, and personalized
            guidance, we ensure that each student can get the best support and
            achieve the desired results.
          </p>

          <div className="k3study-introduction-section2_mobile">
            <img src={ladyBg} className="k3study_introduction_ladyBg" alt="" />
            <img src={lady} className="k3study_introduction-main-img" alt="" />
            <img className="k3study_introduction_bubble" src={bubble} alt="" />
            <img
              className="k3study_introduction_pinkBubble"
              src={pinkbubble}
              alt=""
            />
            <img className="k3study_introduction_chat" src={chat} alt="" />
            <img
              className="k3study_introduction_greenChat"
              src={greenchat}
              alt=""
            />
            <img
              className="k3study_introduction_teachers"
              src={group10}
              alt=""
            />
            <img
              className="k3study_introduction_clients"
              src={group20}
              alt=""
            />
            <img
              className="k3study_introduction_students"
              src={group300}
              alt=""
            />
          </div>
          <p className="k3study-introduction-paragraph">
            We are the best institute for IELTS, PTE, TOEFL, OET, GMAT, and SAT
            coaching. Our mission is to provide world-class education and
            guidance to our students for achieving their academic goals.
          </p>
          <div className="k3study-introduction-segments_main">
            <div className="k3study-introduction-segments">
              <img src={red} alt="" />
              <h6 className="k3study_introduction-segment-text">
                Expert Teachers
              </h6>
            </div>
          </div>
          <button className="k3study_introduction-button">Explore More</button>
        </div>
        <div className="k3study-introduction-section2">
          <img src={ladyBg} className="k3study_introduction_ladyBg" alt="" />
          <img src={lady} className="k3study_introduction-main-img" alt="" />
          <img className="k3study_introduction_bubble" src={bubble} alt="" />
          <img
            className="k3study_introduction_pinkBubble"
            src={pinkbubble}
            alt=""
          />
          <img className="k3study_introduction_chat" src={chat} alt="" />
          <img
            className="k3study_introduction_greenChat"
            src={greenchat}
            alt=""
          />
          <img className="k3study_introduction_teachers" src={group10} alt="" />
          <img className="k3study_introduction_clients" src={group20} alt="" />
          <img
            className="k3study_introduction_students"
            src={group300}
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default Introduction;
