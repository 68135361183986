import React, { useEffect } from "react";
import "./testimonialcard.css";
import TestimonialCardData from "../../../Data/TestimonialCardData";

const TestimonialCard = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="k3study_testimonial-card-main">
      {TestimonialCardData.map((item) => (
        <div key={item.id} className="k3study_testimonial-card">
          <div className="k3study_testimonial-card_img-container">
            <img
              src={item.img}
              className="k3study_testimonial-card-img"
              alt=""
            />
          </div>
          <div className="k3study_testimonial-card_content">
            <h4 className="k3study_testimonial-card-name">{item.name}</h4>
            <p>
              Lorem ipsum dolor sit amet consectetur. In molestie euismod elit
              feugiat in nisi nulla. Dui porttitor vivamus viverra enim bibendum
              suspendisse blandit nunc sollicitudin.
            </p>
            <h4 className="k3study_testimonial-card-rating">Rating</h4>
            <div className="k3study_testimonial-card-stars">
              <img src={item.star} alt="" />
              <img src={item.star} alt="" />
              <img src={item.star} alt="" />
              <img src={item.star} alt="" />
              <img src={item.star} alt="" />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default TestimonialCard;
