import img1 from "../Assets/Images/benefits/img1.png";
import img2 from "../Assets/Images/benefits/img2.png";
import img3 from "../Assets/Images/benefits/img3.png";
import img4 from "../Assets/Images/benefits/img4.png";
import img5 from "../Assets/Images/benefits/img5.png";
import img6 from "../Assets/Images/benefits/img6.png";
const BenefitsData = [
  {
    id: 1,
    img: img1,
    text: "Practice Test",
    desc: "Our institute provides full-length practice tests for IELTS, PTE, TOEFL, OET, GMAT, and SAT to help students understand the exam patterns and practice their skills. All tests are designed to simulate the actual exam environment..",
  },
  {
    id: 2,
    img: img2,
    text: "Customizable Study Plan",
    desc: "Our customized study plans are tailored to each student's individual needs and learning styles. With our experienced instructors, we can help you develop the skills you need to excel in IELTS, PTE, TOEFL, OET, GMAT, and SAT exams. Let us help you reach your educational goals.",
  },
  {
    id: 3,
    img: img3,
    text: "Adapt Trainers",
    desc: "Our experienced trainers provide adapted IELTS, PTE, TOEFL, OET, GMAT, and SAT coaching to help you achieve your desired score in the exam. Our tailored programs are specifically designed to suit your needs and help you reach your goals.",
  },
  {
    id: 4,
    img: img4,
    text: "Online Test",
    desc: "Our institute provides full-length practice tests for IELTS, PTE, TOEFL, OET, GMAT, and SAT to help students understand the exam patterns and practice their skills. All tests are designed to simulate the actual exam environment.",
  },
  {
    id: 5,
    img: img5,
    text: "Unique Learning Methods",
    desc: "IELTS, PTE, TOEFL, OET, GMAT, and SAT coaching at our institute is based on a unique learning model that helps students to learn and understand concepts easily and interactively. Our experienced tutors provide personalized guidance and support to each student.",
  },
  {
    id: 6,
    img: img6,
    text: "24/7 hr Chat Support",
    desc: "Our 24*7, chat support team is here to help you with all your queries related to IELTS, PTE, TOEFL, OET, GMAT, and SAT coaching. Get in touch with us anytime and we'll be happy to assist you.",
  },
];
export default BenefitsData;
