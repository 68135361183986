import React from "react";
import CallExperts from "../Common/CallExperts/CallExperts";
import IeletsCards from "../Components/CanadaPR Components/Ielets Cards/IeletsCards";
import IeletsList from "../Components/CanadaPR Components/Ielets List/IeletsList";
import StudentReview from "../Components/Student Reviews/StudentReview";
import WhatIsIelets from "../Components/CanadaPR Components/WhatIsIelets/WhatIsIelets";
// import Contact from "../Components/Landing Page Components/Contact/Contact";
import Hero from "../Common/Hero/Hero";
import main from "../Assets/Images/Ielets/main.png";
import { useEffect } from "react";

const CanadaPR = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className="k3study_hero-counter">
        <Hero
          img={main}
          title="Canada PR"
          paragraph=" We understand that the dream of moving to Canada and obtaining permanent residency is a significant step towards building a better future. Our experienced team is here to guide you through the intricate process, making your journey towards Canadian PR as smooth and successful as possible."
        />
      </div>
      <WhatIsIelets />
      <IeletsCards />
      <IeletsList />
      <StudentReview />
      <CallExperts />
    </div>
  );
};

export default CanadaPR;
