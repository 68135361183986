import React from "react";
import "./whatisielets.css";
import { Link, useNavigate } from "react-router-dom";
import it from "../../../Assets/jobcard/it.png";
import nonit from "../../../Assets/jobcard/nonit.png";

const WhatIsIelets = () => {
  const data = [
    {
      id: 1,
      img: it,
      title: "IT Recruitment",
      desc: "Find top IT talent for your organization with our specialized IT recruitment services.",
    },
    {
      id: 2,
      img: nonit,
      title: "Non IT Recruitment",
      desc: "Explore rewarding career opportunities across diverse industries with our expert non-IT recruitment services.",
    },
  ];

  const history = useNavigate();

  function navigateToLinkAndScrollTop(link) {
    window.scrollTo(0, 0);
    history.push(link);
  }

  return (
    <div className="recruitment_jobcard">
      <Link
        to="/job-details"
        onClick={() => navigateToLinkAndScrollTop("/job-details")}
      >
        <div className="recruitment_jobcard_cards-div">
          {data.map((item) => (
            <div key={item.id} className="recruitment_jobcard_card">
              <img
                className="recruitment_jobcard_card_img"
                src={item.img}
                alt=""
              />
              <p className="recruitment_jobcard_card_title">{item.title}</p>
              <p className="recruitment_jobcard_card_desc">{item.desc}</p>
            </div>
          ))}
        </div>
      </Link>
    </div>
  );
};

export default WhatIsIelets;
