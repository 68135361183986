import React from "react";
import { DemoContact } from "../Components/Landing Page Components/Contact/Contact";
import TestimonialCard from "../Components/Testimonial Components/Testimonial Card/TestimonialCard";
import Hero from "../Common/Hero/Hero";
import satmain from "../Assets/Images/SAT/satmain.png";
import { useEffect } from "react";

const TestimonialPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className="k3study_hero-counter">
        <Hero img={satmain} title="A lot of happy students " />
      </div>
      <TestimonialCard />
      <DemoContact />
    </div>
  );
};

export default TestimonialPage;
