import React from "react";
import "./ieletslist.css";
import icon1 from "../../../Assets/Images/Ielets/icon1.png";
import icon2 from "../../../Assets/Images/Ielets/icon2.png";
import icon3 from "../../../Assets/Images/Ielets/icon3.png";
import icon4 from "../../../Assets/Images/Ielets/icon4.png";
import icon5 from "../../../Assets/Images/Ielets/icon5.png";
import icon6 from "../../../Assets/Images/Ielets/icon6.png";
import list from "../../../Assets/Images/Ielets/list.png";
const IeletsList = () => {
  return (
    <div>
      <div className="k3study_ieletslist-container">
      <h1>Why Choose K3study?</h1>
        <p>Choosing the right immigration consultant is crucial for a successful PR application. At K3study, we stand out because of:</p>
        </div>
      <div className="k3study_ieletslist-main">
        
        <div className="k3study_ieletslist">
          <div className="k3study_ieletslist-components">
            <div className="k3study_ieletslist-icon">
              <img src={icon1} alt="" />
            </div>
            <h4 className="k3study_ieletslist-text">
              Experience: Our team has a proven track record of helping clients achieve their Canadian PR goals.
            </h4>
          </div>
          <div className="k3study_ieletslist-components">
            <div className="k3study_ieletslist-icon">
              <img src={icon2} alt="" />
            </div>
            <h4 className="k3study_ieletslist-text">
              Personalized Service: We treat every client as an individual with unique needs and aspirations.
            </h4>
          </div>
          <div className="k3study_ieletslist-components">
            <div className="k3study_ieletslist-icon">
              <img src={icon3} alt="" />
            </div>
            <h4 className="k3study_ieletslist-text">
              Transparency: We believe in open and honest communication throughout the process.
            </h4>
          </div>
          <div className="k3study_ieletslist-components">
            <div className="k3study_ieletslist-icon">
              <img src={icon4} alt="" />
            </div>
            <h4 className="k3study_ieletslist-text">
              Success Rate: Our high success rate speaks to our expertise and dedication.
            </h4>
          </div>
          
        </div>
        <div className="k3study_ieletslist-img">
          <img className="k3study_ieletslist-girl-img" src={list} alt="" />
        </div>
      </div>
    </div>
  );
};

export default IeletsList;
