import React from "react";
import StudentReview from "../Components/Student Reviews/StudentReview";
import Contact from "../Components/Landing Page Components/Contact/Contact";
import Hero from "../Common/Hero/Hero";
import main from "../Assets/Images/Pte/main.png";
import AboutPte from "../Components/Pte Components/About Pte/AboutPte";
import { useEffect } from "react";

const PtePage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className="k3study_hero-counter">
        <Hero
          img={main}
          title="Online PTE course"
          paragraph="Our PTE coaching institute provides comprehensive training for Preliminary English Test. With our experienced instructors and rigorous curriculum, we guarantee success in the exam. Our lessons cover all areas of the test, including reading, writing, listening, and speaking. We also provide personalized practice exercises to hone your skills and maximize your score."
        />
      </div>
      <AboutPte />
      <StudentReview />
      <Contact />
    </div>
  );
};

export default PtePage;
