import React from "react";
import EnquiryForm from "../EnquiryForm/EnquiryForm";
import "./hero.css";

const Hero = (props) => {
  return (
    <div className="k3study_hero-component-main">
      <div>
        <img
          src={props.img}
          className="k3study_carousel_img k3study_hero_bg-img"
          alt=""
        />
        <div className="k3study_hero-component_carousal_main">
          <div className="k3study_hero-component_carousal-content">
            <h2 className="k3study_hero-component_heading">{props.title}</h2>
            <p className="k3study_hero-component_para">{props.paragraph}</p>
          </div>
          <EnquiryForm />
        </div>
      </div>
    </div>
  );
};

export default Hero;
