import React from "react";
import AboutBusiness from "../Components/About Components/About Business/AboutBusiness";
import ContactMap from "../Components/Contact Components/Contact Map/ContactMap";
import { DemoContact } from "../Components/Landing Page Components/Contact/Contact";
import Counter from "../Components/Landing Page Components/Counter/Counter";
import mainabout from "../Assets/Images/About/mainabout.png";
import Hero from "../Common/Hero/Hero";
import { useEffect } from "react";

const AboutPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className="k3study_hero-counter">
        <Hero
          img={mainabout}
          title="About Us"
          paragraph="K3Study is the premier provider of high-quality training for the most popular international English language tests. We provide comprehensive courses and practice materials for IELTS, PTE, TOEFL, OET, GMAT, and SAT."
        />
      </div>
      <Counter />
      <AboutBusiness />
      <DemoContact />
      <ContactMap />
    </div>
  );
};

export default AboutPage;
