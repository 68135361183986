import React from "react";
import { WhiteCards } from "../../../Common/Cards/Cards";
import "./ieletscards.css";

const IeletsCards = () => {
  return (
    <div>
      <div className="k3study_ieletscards-main">
        <WhiteCards
          heading="Eligibility Assessment"
          paragraph="We evaluate your eligibility for Canadian PR programs such as Express Entry, Provincial Nominee Programs (PNPs), Family Sponsorship, and more."
        />
        <WhiteCards
          heading="Documentation Assistance"
          paragraph="We guide you through the intricate process of collecting and organizing the necessary documents for your application."
        />
        <WhiteCards
          heading="Application Submission"
          paragraph="Our team ensures that your application is submitted accurately and within the required timelines."
        />
        <WhiteCards
          heading="Interview Preparation"
          paragraph="If required, we provide interview preparation to help you successfully pass your immigration interviews."
        />
        <WhiteCards
          heading="Post-landing Services"
          paragraph="We assist you with the settlement process in Canada, helping you find accommodation, employment, and providing essential information for a smooth transition."
        />
      </div>
      <div className="k3study_get-quotes">
        <h2 className="k3study_quote_text">
          Let K3study be your trusted partner on your path to Canadian PR.
        </h2>
        <h2 className="k3study_quote_btn">GET QUOTE</h2>
      </div>
    </div>
  );
};

export default IeletsCards;
