import React from "react";
import heart from "../../../Assets/Images/counter/heart.png";
import star from "../../../Assets/Images/counter/star.png";
import line from "../../../Assets/Images/counter/line.png";
import "./counter.css";

const Counter = () => {
  return (
    <div className="k3study_counter-main">
      <div className="k3study_counter-section">
        <h2 className="k3study_counter-heading">100% QUALITY</h2>
        <p className="k3study_counter-paragraph">
          We never compromise in delivering quality training
        </p>
      </div>
      <div className="k3study_counter-section">
        <h2 className="k3study_counter-heading">100% RESULTS</h2>
        <p className="k3study_counter-paragraph">Our methods undoubedtly bring results</p>
      </div>
      <div className="k3study_counter-section">
        <h2 className="k3study_counter-heading">100% DEDICATION</h2>
        <p className="k3study_counter-paragraph">We work with our students closely to get scores</p>
      </div>
    </div>
  );
};

export default Counter;
