import React from "react";
import "../Practice/practice.css";
import practice from "../../../Assets/Images/Practice/practice.png";
const Practice = () => {
  return (
    <div className="k3study_practice-main">
      <div className="k3study_practice-section1">
        <div className="k3study_practice-blue">
          Practice anytime anywhere
          <h3 className="k3study_practice_title">
            Solving problems with clarity, simplicity & Honesty
          </h3>
        </div>
        <div className="k3study_practice-section2_mobile">
          <img className="k3study_practice_img" src={practice} alt="" />
        </div>
        <p className="k3study_practice_desc">
          We have adaptive online portals where you can learn, practice and
          understand your weak areas. Video lessons, Practice questions, pace,
          analytics, Solutions, Weak areas.
        </p>
      </div>
      <div className="k3study_practice-section2">
        <img src={practice} alt="" className="k3study_practice-section2-img" />
      </div>
    </div>
  );
};

export default Practice;
