import React from "react";
import "./aboutpte.css";

const AboutPte = () => {
  return (
    <div className="k3study_about-pte_main">
      <h1>Why PTE?</h1>
      <p className="k3study_about-pte-paragraph">
        The Pearson Test of English (PTE) is an important assessment for
        academic and professional English language proficiency. It is a reliable
        measure of English language abilities, used by organizations around the
        world. The PTE is a high-quality assessment with an efficient delivery
        system which provides timely and cost-efficient results. It assesses all
        four skills of the English language (reading, writing, speaking, and
        listening) in one comprehensive test. Its flexible format and results
        that can be reported to universities, employers, and other organizations
        make it an invaluable resource for those seeking to demonstrate their
        proficiency.
      </p>
      <p className="k3study_about-pte-paragraph">
        <span className="k3study_pte_span">
          The PTE is opted over other English language proficiency tests because
          :
        </span>
        The PTE is accepted by universities and immigration bodies all around
        the world. PTE body does not charge any additional fees for sending
        additional scores to universities. PTE aspirants get their results
        within 48 hours of the test.
      </p>
    </div>
  );
};

export default AboutPte;
