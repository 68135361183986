import React from "react";
import StudentReview from "../Components/Student Reviews/StudentReview";
import Contact from "../Components/Landing Page Components/Contact/Contact";
import AboutOet from "../Components/Oet Components/AboutOet/AboutOet";
import Hero from "../Common/Hero/Hero";
import mainimg from "../Assets/Images/Oet/mainimg.png";
import { useEffect } from "react";

const OetPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className="k3study_hero-counter">
        <Hero img={mainimg} title="Online OET course" paragraph="Our OET coaching institute provides comprehensive training for the Occupational English Test. Our experienced instructors provide lessons covering all areas of the exam, including reading, writing, listening, and speaking. We also provide personalized practice exercises to hone your skills and maximize your score" />
      </div>
      <AboutOet />
      <StudentReview />
      <Contact />
    </div>
  );
};

export default OetPage;
