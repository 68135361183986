import React from "react";
import AboutBusiness from "../Components/ItRecruit Components/About Business/AboutBusiness";
import ContactMap from "../Components/Contact Components/Contact Map/ContactMap";
import { DemoContact } from "../Components/Landing Page Components/Contact/Contact";
import Why from "../Components/ItRecruit Components/Ielets List/IeletsList";
import Recruit from "../Components/ItRecruit Components/WhatIsIelets/WhatIsIelets";
import Counter from "../Components/Landing Page Components/Counter/Counter";
import mainabout from "../Assets/Images/About/mainabout.png";
import Hero from "../Common/Hero/Hero";
import { useEffect } from "react";

const ITrecruit = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className="k3study_hero-counter">
        <Hero
          img={mainabout}
          title="Recruitment"
          paragraph="Explore endless possibilities and find your dream job with our recruitment agency. Browse through a wide range of exciting opportunities and take the first step towards a fulfilling career. Your perfect job awaits!"
        />
      </div>
      <Counter />
      <Recruit />
      <Why />
      <AboutBusiness />
      <DemoContact />
      <ContactMap />
    </div>
  );
};

export default ITrecruit;


