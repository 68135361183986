import React from "react";
import "./aboutgmat.css";
const AboutGmat = () => {
  return (
    <div className="k3study_about-gmat_main">
      <h1>What is GMAT?</h1>
      <p className="k3study_about-gmat-paragraph">
        The Graduate Management Admission Test (GMAT) is a standardized test
        used by graduate business schools to assess a prospective student's
        academic qualifications for admission into a graduate business degree
        program. The GMAT is designed to measure a student's written, verbal,
        quantitative, analytical and critical thinking skills. It is the only
        admissions test accepted by all graduate business schools, including
        top-tier MBA programs around the world.
      </p>
      <p className="k3study_about-gmat-paragraph">
        The GMAT exam consists of four sections: Analytical Writing Assessment,
        Integrated Reasoning, Quantitative Reasoning and Verbal Reasoning. Each
        section is scored independently, and the scores are combined to give the
        overall score.
      </p>
      <p className="k3study_about-gmat-paragraph">
        The Analytical Writing Assessment measures your ability to analyze an
        argument and to effectively communicate your evaluation of the argument.
        You will be given an argument and asked to write an essay that evaluates
        the argument.
      </p>
      <p className="k3study_about-gmat-paragraph">
        The Integrated Reasoning section tests your ability to analyze and
        evaluate data from various sources. You will be given a variety of data
        sources, such as tables, graphs and text, and you will be asked to
        interpret and draw conclusions from them.
      </p>
      <p className="k3study_about-gmat-paragraph">
        The Quantitative Reasoning and Verbal Reasoning sections are designed to
        measure your ability to solve quantitative and verbal problems,
        respectively. The Quantitative Reasoning section consists of questions
        involving basic math and data analysis, while the Verbal Reasoning
        section consists of questions involving critical reasoning and reading
        comprehension.
      </p>
      <p className="k3study_about-gmat-paragraph">
        The GMAT is designed to measure your aptitude for graduate business
        studies, and it is an important part of the admissions process for most
        graduate business programs. It is important to prepare thoroughly for
        the GMAT, as a high score can give you an edge when applying to business
        schools.
      </p>
      <h1>Why GMAT?</h1>
      <div className="k3study_about-gmat-list">
        <ul>
          <li className="k3study_about-gmat_li">
            Provides a standardized score that is accepted by graduate schools
            worldwide
          </li>
          <li className="k3study_about-gmat_li">
            Employers often look for GMAT scores to gauge the professional
            abilities of job candidates.
          </li>
          <li className="k3study_about-gmat_li">
            Having a high GMAT score increases scholarly competence.
          </li>
          <li className="k3study_about-gmat_li">
            GMAT scores are a sign that a graduate school candidate has the
            skills necessary to succeed in their chosen field.
          </li>
          <li className="k3study_about-gmat_li">
            GMAT scores are considered a reliable measure of a test-taker's
            potential for future business success.
          </li>
          <li className="k3study_about-gmat_li">
            GMAT scores are used by many employers to compare potential workers
            and make hiring decisions.
          </li>
          <li className="k3study_about-gmat_li">
            GMAT is the most widely used test to evaluate the skills and
            knowledge of applicants for admission to graduate schools.
          </li>
          <li className="k3study_about-gmat_li">
            GMAT scores reflect a student's ability to think critically, analyze
            data, and evaluate their decision making skills.
          </li>
          <li className="k3study_about-gmat_li">
            GMAT scores help business schools determine their acceptance rates,
            as well as the overall quality of their incoming classes.
          </li>
          <li className="k3study_about-gmat_li">
            The exam is a valid metric for assessing a graduate school
            candidate's readiness for the rigorous academic standards of a
            graduate business program.
          </li>
        </ul>
      </div>
    </div>
  );
};

export default AboutGmat;
