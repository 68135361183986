import React from "react";
import "./contactmap.css";

const ContactMap = () => {
  return (
    <iframe
      title="contactMap"
      className="k3study_contact-map"
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d30688870.953534134!2d64.39286061192526!3d20.125699284426457!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb9134e0a6da21%3A0xec5e33e51aecc38d!2sK3Study%20-%20IELTS%2C%20TOEFL%2C%20%26%20PTE.%20Learn%20skills%20to%20crack%20the%20test.!5e0!3m2!1sen!2sin!4v1672652213331!5m2!1sen!2sin"
      loading="lazy"
    ></iframe>
  );
};

export default ContactMap;
