import React from "react";
import "./testimonials.css";
import TestimonialData from "../../../Data/TestimonialData";
import { NavLink } from "react-router-dom";
import { FaUserCircle } from "react-icons/fa";

const Testimonials = () => {
  return (
    <div className="k3study_testimonials-main">
      <div className="k3study_testmonials_title">
        <h5 className="k3study_testmonials-testimonials">TESTIMONIALS</h5>
        What our Happy Student say
      </div>
      <div className="k3study_testimonials-content">
        {TestimonialData.map((item) => (
          <div key={item.id} className="k3study_testimonials-card">
            <div className="k3study_testimonials_student_info">
              <div className="k3study_testimonials_student_div">
                <FaUserCircle className="k3study_testimonials_student_img" />
                <div className="k3study_testimonials_student_details">
                  <p className="k3study_testimonials_student-name">
                    {item.name}
                  </p>
                  <p className="k3study_testimonials_role">{item.role}</p>
                </div>
              </div>
              <div className="k3study_testimonials_student_stars">
                <p className="k3study_testimonials_rating-text">Rating</p>
                {item.stars}
              </div>
            </div>
            <div className="k3study_testimonials_decs">{item.desc}</div>
          </div>
        ))}
      </div>
      <NavLink to="/testimonial">
        <div className="k3study_testimonials_btns">
          <div className="k3study_testimonials_btn"></div>
          <div className="k3study_testimonials_btn"></div>
          <div className="k3study_testimonials_btn"></div>
        </div>
      </NavLink>
    </div>
  );
};

export default Testimonials;
