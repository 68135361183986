import React from "react";
import "./whatisielets.css";

const WhatIsIelets = () => {
  return (
    <div className="k3study_what-is-iletes_main">
      <h1>Why Choose Canadian Permanent Residency? </h1>
      <p>
        Canada is known for its high quality of life, robust healthcare system, excellent education, and diverse job opportunities. Obtaining Canadian PR opens doors to a plethora of benefits, including:
      </p>
     
      <p>
        1. Freedom and Stability: As a Canadian Permanent Resident, you and your family can live, work, or study anywhere in Canada.
      </p>
      <p>
        2. Access to Healthcare: You will enjoy access to Canada's world-class healthcare system, one of the best in the world.
      </p>
      <p>
        3. Education Opportunities: Canadian PR provides your children with access to some of the world's top-ranking schools and universities.
      </p>
      <p>
        4. Social Benefits: Permanent Residents can avail of social benefits and programs designed to support families and individuals.
      </p>
      <p>
        5. Path to Citizenship: Canadian PR is a stepping stone to Canadian citizenship, offering long-term security and stability.
      </p>
      <h1>Our Canada PR Services:</h1>
      <p>
        At K3study, we offer a comprehensive range of services tailored to your unique needs and circumstances. Our dedicated team of immigration experts will assist you in every step of the Canadian PR journey, including:
      </p>
    </div>
  );
};

export default WhatIsIelets;
