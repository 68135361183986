import React from "react";
import "../../../Common/Hero/hero.css";

const ContactHero = (props) => {
  return (
    <div className="k3study_hero-component-main">
      <div>
        <img
          src={props.img}
          className="k3study_carousel_img k3study_hero_bg-img"
          alt=""
        />
        <div className="k3study_hero-component_carousal_main">
          <div className="k3study_hero-component_carousal-content">
            <h2 className="k3study_hero-component_heading">{props.title}</h2>
            <p className="k3study_hero-component_para">
              K3 Study makes learning easy, convenient, and fun. Get in touch
              with us to find out how we can help you reach your academic goals.
              Our team of experts is here to answer any questions you may have.
              Reach out to us via email, phone, or our contact form. We look
              forward to hearing from you!
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactHero;
